:root {
  --color1: #f00f5e;
  --color2: #ed00f1;
  --light-bg: #f9f9f9f4;
}

* {
  overflow-x: hidden;
  margin: 0;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {

  font-family: 'Futura', 'Avenir', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9f4;
  /* background-color: #2b2b2bf4; */
  /* color: rgb(255, 255, 255); */
  text-align: center;
}



header {
  margin: 4rem;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;


  h1 {
    margin: 2rem;
    font-size: 4rem;
    text-transform: uppercase;
    background-image: linear-gradient(4deg, var(--color1), var(--color2) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

  }




  p {
    font-size: 1.2rem;
    max-width: 60vw;

  }


}

a {
  text-decoration: none;
  color: #f00f5e;
  font-weight: bold;
}

.dayofweek {
  font-size: 2.4rem;
  text-transform: uppercase;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;

}


h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

hr {
  border: 1px solid rgb(124, 124, 124);
  width: 80vw;
  margin: 10vw;

}

.shown {
  color: var(--color1);
  text-shadow: none;
}

.hidden {
  color: transparent;
  text-shadow: 0 0 10px var(--color1);

  &::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: "reveal the solution";
    font-size: 2rem;
    color: black;
    background-color: white;
  }
}


input {
  height: 4rem;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  font-size: 1.6rem;
  text-transform: uppercase;
  background-image: linear-gradient(4deg, var(--color1) 0%, var(--color2) 100%);
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.randomdate {
  height: 6rem;
  font-weight: bold;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  font-size: 1.6rem;
  text-transform: uppercase;
  background-image: linear-gradient(4deg, var(--color1), var(--color2) 100%);
  color: white;
  cursor: pointer;

}

table {
  text-align: center;
  font-size: 1.4rem;
  text-transform: uppercase;

  td {
    padding: 5px;
  }
}

#method {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-size: 1.2rem;
  /* text-transform: uppercase; */

}


.datestate {
  text-transform: uppercase;
  font-size: 1.2rem;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.chosendate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: black;
  font-size: 2.4rem;
  text-transform: uppercase;
  /* make it fade in */
  opacity: 0;
  animation: fadeIn 1s forwards;


}


h1 {
  text-transform: uppercase;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

}


.accordion {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-size: 1.2rem;
  /* text-transform: uppercase; */
}

.accordion-content {
  display: block;
  overflow: hidden;

  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  /* text-transform: uppercase; */
}

.explanation {
  padding: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-size: 1.2rem;
  /* text-transform: uppercase; */
  border: white 1px solid;
}

.tip {
  color: #f00f5e;
  font-weight: bold;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  font-size: 1.2rem;
  /* text-transform: uppercase; */
  padding: 20px;
  margin: 20px;

  img {
    width: 100px;
    height: 100px;
    mix-blend-mode: darken;
  }
}

.logicdiagram {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem 1rem;
  margin: 2rem;


  &>* {
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
  }

  .query {
    background-image: linear-gradient(4deg, var(--color1), var(--color2) 100%);
    padding: 2rem;
    border-radius: 2rem;
    color: white;
  }

  .divide {
    grid-row: span 3;
    background-image: linear-gradient(4deg, var(--color1), var(--color2) 100%);
    padding: 2rem;
    color: white;
  }

  .add {
    background-image: linear-gradient(4deg, var(--color1), var(--color2) 100%);
    padding: 2rem;
    color: white;
  }

  .final {
    grid-row: span 3;
    background-image: linear-gradient(4deg, var(--color1), var(--color2) 100%);
    padding: 2rem;
    color: white;
  }


  .arrow {
    font-size: 4rem;



  }

  .no::before {
    position: absolute;
    transform: translateY(-100%);
    content: "N";
    font-size: 1rem;
    z-index: 99;
    background-color: var(--light-bg);
    padding: 2px;
    font-size: 1.2rem;
  }

  .yes::before {
    position: absolute;
    transform: translate(-150%, 25%);

    content: "Y";
    font-size: 1rem;
    z-index: 99;
    background-color: var(--light-bg);
    padding: 2px;
    font-size: 1.2rem;
  }

}